<template>
  <div class="flex flex-col mt-6 space-y-5">
    <div>
      <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
        Dashboard
      </h2>
    </div>
    <div
      class="w-auto max-w-md p-4 space-y-2 bg-white border border-gray-300 shadow rounded-xl dark:bg-gray-700 dark:border-gray-400">
      <div class="flex">
        <h3 class="text-lg font-medium text-gray-700 dark:text-gray-200">
          Load stats for selected dates
        </h3>
      </div>
      <div>
        <div class="max-w-xs">
          <label>
            <span class="text-gray-700 label dark:text-gray-200">Start</span>
            <input v-model="dates.start" type="date" class="input" />
          </label>
        </div>
        <div class="max-w-xs mt-2">
          <label>
            <span class="text-gray-700 label dark:text-gray-200">End</span>
            <input v-model="dates.end" type="date" class="input" />
          </label>
        </div>
        <div class="max-w-xs mt-4">
          <button class="w-full p-2 text-white focus:outline-none rounded-xl" :class="
            dates.start && dates.end
              ? 'bg-indigo-700 dark:bg-primary-light'
              : 'bg-gray-400 dark:bg-gray-500'
          " @click="loadStats()">
            Search
          </button>
          <button class="w-full p-2 mt-2 text-white focus:outline-none rounded-xl" :class="
            dates.start && dates.end
              ? 'bg-indigo-700 dark:bg-primary-light'
              : 'bg-gray-400 dark:bg-gray-500'
          " @click="deleteStats()">
            Delete ⚠️
          </button>
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-lg font-semibold text-gray-700 dark:text-gray-400">
        Collection stats for {{ dates.start | humanDate }} -
        {{ dates.end | humanDate }}
      </h2>
    </div>
    <div class="flex flex-col flex-wrap space-y-2 md:flex-row md:space-x-4 md:space-y-0">
      <div
        class="max-w-xs p-4 text-white border-2 border-indigo-900 shadow-lg bg-primary-light rounded-xl dark:bg-indigo-600 dark:border-indigo-300"
        v-for="cafe in cafes" :key="cafe.nano_id">
        <p class="text-lg font-bold">{{ cafe.name }}</p>
        <p class="font-mono">₹{{ getCafeTotal(cafe.id) }}</p>
      </div>
      <div
        class="max-w-xs p-4 text-white border-2 border-indigo-900 shadow-lg bg-primary-light rounded-xl dark:bg-indigo-600 dark:border-indigo-300">
        <p class="text-lg font-bold">Total pending amount till date</p>
        <p class="font-mono">₹{{ unpaid }}</p>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-6">
      <div v-for="stat in stats" :key="stat.name + 'statistics' + stat.cafe_id"
        class="max-w-xs p-4 text-white border-2 border-indigo-900 shadow-lg bg-primary-light rounded-xl dark:bg-indigo-600 dark:border-indigo-300">
        <p class="text-lg font-bold capitalize">
          {{ stat.name }} | {{ getCafe(stat.cafe_id) }}
        </p>
        <p class="font-mono">₹{{ stat.total_paid }}</p>
      </div>
    </div>
    <h2 class="text-lg font-semibold text-gray-700 dark:text-gray-400">
      Inventory stats for {{ dates.start | humanDate }} -
      {{ dates.end | humanDate }}
    </h2>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-6">
      <div v-for="inv in inventory" :key="inv.name + 'statistics'"
        class="max-w-xs p-4 text-white border-2 border-indigo-900 shadow-lg bg-primary-light rounded-xl dark:bg-indigo-600 dark:border-indigo-300">
        <p class="text-lg font-bold capitalize">
          {{ inv.name }}
        </p>
        <p class="font-mono">Total {{ inv.totalConsumed }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import isEmpty from "ramda/es/isEmpty";
export default {
  name: "Dashboard",
  data() {
    return {
      dates: {
        start: "",
        end: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      stats: ["dashboard/getStats"],
      unpaid: ["dashboard/getUnpaid"],
      inventory: ["dashboard/getInventory"],
      cafes: ["cafe/getCafes"],
    }),
    getCafe() {
      return (cafe_id) => {
        if (isEmpty(this.cafes)) {
          return "";
        }
        let filtered = this.cafes.filter((c) => c.id === Number(cafe_id));
        if (!isEmpty(filtered)) {
          return filtered[0].name;
        }
        return "";
      };
    },
    getCafeTotal() {
      return (cafe_id) => {
        if (!isEmpty(this.cafes) && !isEmpty(this.stats)) {
          let filtered = this.stats.filter(
            (stat) => Number(stat.cafe_id) === Number(cafe_id)
          );
          if (!isEmpty(filtered)) {
            return filtered.reduce((a, b) => +a + +b.total_paid, 0);
          }
        }
        return 0;
      };
    },
  },
  mounted() {
    this.loadStats();
    if (isEmpty(this.cafes)) {
      this.loadCafes();
    }
  },
  methods: {
    loadStats() {
      this.$store.dispatch("dashboard/loadStats", {
        start: this.dates.start,
        end: this.dates.end,
      });
    },
    deleteStats() {
      this.$store.dispatch("dashboard/deleteStats", {
        start: this.dates.start,
        end: this.dates.end,
      });
    },
    loadCafes() {
      this.$store.dispatch("cafe/getCafes");
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      let today;
      if (!date) {
        today = new Date();
      } else {
        today = new Date(date);
      }
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style>

</style>
